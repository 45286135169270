import React, { useEffect, useState } from 'react';
import { getPage, getPageContentTypes } from '../../../get_preview_query';
import { format, renderPreviewComponents } from '../../components/cms-modules';
import { useLocale } from '../../context/locale.context';
import Layout from '../../modules/layout';
import SEO from '../../modules/seo';

const PreviewPage = () => {
  const { locale } = useLocale();

  const [page, setPage] = useState(null);
  const gqlUrl = `${process.env.GATSBY_CONTENTFUL_GRAPHQL_URL}/${process.env.GATSBY_CONTENTFUL_SPACE_ID}/environments/${process.env.GATSBY_CONTENTFUL_ENVIRONMENT}`;
  const authHeader = `Bearer SOEGLctP3y-WLjmF6YIv1juHhNn9dOBOvp98vtQuFnY`;

  useEffect(() => {
    const url = new URL(window.location);
    const fetchPage = async () => {
      // first fetch page content types to avoid query size limit
      const typesResponse = await fetch(gqlUrl, {
        method: 'POST',
        body: JSON.stringify({
          operationName: null,
          query: getPageContentTypes(
            url.searchParams.get('componentId'),
            locale
          ),
          variables: {},
        }),
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
        },
      });
      const typesJson = await typesResponse.json();
      const includedTypes = typesJson.data?.page?.content?.items.map(
        (el) => el.__typename
      );
      const query = getPage(
        url.searchParams.get('componentId'),
        locale,
        includedTypes
      );
      const res = await fetch(gqlUrl, {
        method: 'POST',
        body: JSON.stringify({
          operationName: null,
          query,
          variables: {},
        }),
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
        },
      });

      const json = await res.json();
      setPage(format(json.data.page));
    };
    fetchPage();
  }, []);

  return page ? (
    <Layout
      hasWhiteNavigation={page.hasWhiteNavigation}
      heroTeaser={page.heroTeaser}
      heroCarouselTeaser={page.heroCarouselTeaser}
      heroCarouselItems={page.heroCarousel}
      preview
    >
      <SEO title={page.title} />
      {page && renderPreviewComponents(page.content, locale)}
    </Layout>
  ) : null;
};

export default PreviewPage;
